// Body medium

import styled from '@emotion/styled';
import { COLORS } from './variables';

const ParagraphMedium = styled.p`
  color: ${COLORS.BLACK};
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
`;

export default ParagraphMedium;
