// Title small

import styled from '@emotion/styled';
import { COLORS, BREAKPOINTS } from './variables';

const Heading3 = styled.h3`
  color: ${COLORS.BLACK};
  font-size: 32px;
  line-height: 40px;
  font-weight: 300;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export default Heading3;
