import React from 'react';
import { css } from '@emotion/core';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Heading3 from '../ui/Heading3';
import { TeamMemberBioQuery } from '../../graphql-types';
import ParagraphLarge from '../ui/ParagraphLarge';
import ParagraphMedium from '../ui/ParagraphMedium';
import { BREAKPOINTS } from '../ui/variables';

export const pageQuery = graphql`
  query TeamMemberBio($slug: String) {
    contentfulPerson(urlSlug: { eq: $slug }) {
      avatar {
        fluid(maxWidth: 250, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
      position {
        positionName
      }
      name
      specialty
      accreditations
      bio {
        bio
      }
      seoDescription
    }
  }
`;

const TeamMemberBio = ({ data }: { data: TeamMemberBioQuery }) => {
  const person = data.contentfulPerson;
  const title = `${person.name}, ${person.accreditations}`;

  const bio = person.bio.bio
    .split('\n')
    .reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);

  return (
    <Layout>
      <SEO title={title} description={person.seoDescription} />
      <div
        css={css`
          max-width: 800px;
          padding: 100px 0;
          margin: auto;

          @media ${BREAKPOINTS.TABLET} {
            padding: 60px 40px;
          }

          @media ${BREAKPOINTS.MOBILE} {
            padding: 40px 20px;
          }
        `}
      >
        <Heading3>{title}</Heading3>
        <ParagraphLarge>
          <Img
            css={css`
              float: right;
              max-width: 250px;
              width: 100%;
              margin: 0 0 40px 40px;
              border-radius: 4px;

              @media screen and (max-width: 600px) {
                float: inherit;
                margin-left: auto;
                margin-right: auto;
              }
            `}
            fluid={person.avatar.fluid}
          />
          {bio}
        </ParagraphLarge>
        <ParagraphMedium>Special Interests: {person.specialty}</ParagraphMedium>
        <Link to='/team'>&lt; Back to team</Link>
      </div>
    </Layout>
  );
};

export default TeamMemberBio;
